
import { Component, Vue } from 'vue-property-decorator';
import { UserRepository } from '@/repositories/UserRepository';
import LanguagePicker from '@/components/views/userSettings/LanguagePicker.vue';
import User from '@/models/User';
import { RouteNames } from '@/enums/routes/RouteNames';
import { LocalStorageService } from '@/services/LocalStorageService';
import { LocalStorageKeyNames } from '@/enums/global/LocalStorageKeyNames';
import { Row } from 'ant-design-vue';
import { Col } from 'ant-design-vue';
import UserSettingsTopBar from '@/components/views/userSettings/UserSettingsTopBar.vue';
// @ts-ignore
import jwt_decode from 'jwt-decode';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { CheckUnsavedDataMixin } from '@/mixins/CheckUnsavedData';
import Currency from '@/models/Currency';
import CurrencyRepository from '@/repositories/CurrencyRepository';
import CMSUser from '@/models/CMSUser';

@Component({
    name: 'UserSettings',
    components: { UserSettingsTopBar, LanguagePicker, Col, Row },
    mixins: [CheckUnsavedDataMixin],
})
export default class UserSettings extends Vue {
    private backRoute: RouteNames = RouteNames.home;
    private saveAllUserSettings: EventBusEvents = EventBusEvents.saveAllUserSettings;
    private hasUnsavedData = false;
    private isCacheResetIconLoading = false;

    private userInfo: {
        username: string;
        email: string;
        currency: null | string;
    } = {
        username: '',
        email: '',
        currency: null,
    };

    private get currentUser() {
        return UserRepository.getCurrentUser();
    }

    private get currencies() {
        return CurrencyRepository.getAll();
    }

    private get envVars() {
        return process.env;
    }

    private async logout() {
        await User.logout();
        this.$router.push({ name: RouteNames.login });
    }

    private onChangesInDataMade({ state }: { state: boolean }) {
        this.hasUnsavedData = state;
    }

    private async updateCurrency() {
        this.$emit('toggleLoading');

        if (this.currentUser == null || this.userInfo.currency == null) {
            return;
        }
        try {
            await CMSUser.updateCurrency(this.currentUser.id, this.userInfo.currency);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Došlo je do greške') as string,
                description: ``,
            });
            return;
        }

        await User.get(this.currentUser.id);

        this.$emit('toggleLoading');
        this.$notification.success({
            message: this.$t('Valuta uspješno promijenjena') as string,
            description: ``,
        });
    }

    private resetCache() {
        // Clear cache storage
        sessionStorage.clear();
        this.activateSpinningAnimation();
    }

    private activateSpinningAnimation() {
        this.isCacheResetIconLoading = true;
        setTimeout(() => {
            this.isCacheResetIconLoading = false;
            this.$notification.destroy();
            this.$notification.success({
                message: this.$t('Cache cleared!') as string,
                description: ``,
            });
        }, 1000);
    }

    private async created() {
        this.$emit('toggleLoading');
        EventBus.$on(EventBusEvents.changesInDataMade, this.onChangesInDataMade);
        EventBus.$on(EventBusEvents.saveAllUserSettings, this.updateCurrency);

        if (LocalStorageService.has(LocalStorageKeyNames.token)) {
            const decoded = jwt_decode(LocalStorageService.get(LocalStorageKeyNames.token));
            try {
                await User.get(decoded.id);
            } catch (e) {
                return;
            }
        }
        await Currency.getAll();

        if (this.currentUser == null) {
            return;
        }
        this.userInfo.username = this.currentUser.name;
        this.userInfo.email = this.currentUser.email;
        this.userInfo.currency = this.currentUser.currency != null ? this.currentUser.currency.id : null;
        this.$emit('toggleLoading');
        window.scrollTo(0, 0);
    }

    private async beforeDestroy() {
        EventBus.$off(EventBusEvents.changesInDataMade, this.onChangesInDataMade);
        EventBus.$off(EventBusEvents.saveAllUserSettings, this.updateCurrency);
    }
}
