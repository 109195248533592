
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RouteNames } from '@/enums/routes/RouteNames';
import { UserRightsMixin } from '@/mixins/UserRights';
import { mixins } from 'vue-class-component';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
@Component({
    name: 'UserSettingsTopBar',
})
export default class UserSettingsTopBar extends mixins<UserRightsMixin>(UserRightsMixin) {    
    @Prop({required: false}) private pathBefore!: string;
    @Prop({required: true}) private pathCurrent!: string;

    @Prop({required: false}) private backRoute!: RouteNames;
    @Prop({required: false}) private showBackButton!: boolean;

    @Prop({required: false}) private showSubmitButton!: boolean;
    @Prop({required: false}) private submitButtonText!: string;
    @Prop({required: false}) private submitButtonEvent!: EventBusEvents;


    private goBack(): void {
        this.$router.push({ name: this.backRoute  });
    }

    private submitOnClick(): void{
         EventBus.$emit(this.submitButtonEvent);
    }
}
