
import { Component, Vue } from 'vue-property-decorator';
import LanguagePicker from '@/components/views/userSettings/LanguagePicker.vue';

// @ts-ignore
import { CheckUnsavedDataMixin } from '@/mixins/CheckUnsavedData';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';
import CmsMenu from '../components/views/settings/CmsMenu.vue';
import { UserRightsEnum } from '@/enums/global/UserRights';
import { RouteNames } from '@/enums/routes/RouteNames';
import { CmsEntityTypes } from '@/enums/global/CmsEntityTypes';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import i18n from '@/i18n';
import UserSettings from '@/components/views/settings/UserSettings.vue';

@Component({
    name: 'Settings',
    components: {
        UserSettings,
        CmsMenu,
        LanguagePicker,
        SettingsTopBar,
    },
    mixins: [CheckUnsavedDataMixin],
})
export default class Settings extends Vue {
    private isLoading: boolean = false;

    private menuData: Array<{
        icon: string;
        text: string;
        canSee: boolean;
        redirect: any;
    }> = [
        {
            icon: 'usergroup-add',
            text: i18n.t('Korisnici'),
            canSee: this.canViewUsersTab,
            redirect: this.goToUsers,
        },
        {
            icon: 'font-size',
            text: i18n.t('Prijevodi'),
            canSee: this.canSeeTranslationsItem,
            redirect: this.goToTranslationScreen,
        },
        {
            icon: 'table',
            text: i18n.t('Sadržaj'),
            canSee: this.canSeeCMSItem,
            redirect: this.goToContent,
        },
        {
            icon: 'book',
            text: i18n.t('Katalog'),
            canSee: this.canSeeCatalogueItem,
            redirect: this.goToProductDetails,
        },
        {
            icon: 'cloud-upload',
            text: i18n.t('Ručna ažuriranja'),
            canSee: this.canSeeManualAPITab,
            redirect: this.goToManualAPI,
        },
        {
            icon: 'snippets',
            text: i18n.t('Product versions'),
            canSee: this.canSeeProductVersions,
            redirect: this.goToProductVersions,
        },
    ];
    private goToUsers() {
        if (this.$route.name !== RouteNames.cmsShow || this.$route.params.entityName !== CmsEntityTypes.Users) {
            this.$router.push({
                name: RouteNames.cmsUsers,
                params: {
                    entityName: CmsEntityTypes.Users,
                },
            });
        }
    }
    private goToTranslationScreen() {
        if (this.$route.name !== RouteNames.translationList) {
            this.$router.push({
                name: RouteNames.translationList,
            });
        }
    }

    private goToContent() {
        if (this.$route.name !== RouteNames.cmsContent) {
            this.$router.push({
                name: RouteNames.cmsContent,
            });
        }
    }

    private goToProductDetails() {
        EventBus.$emit(EventBusEvents.deselectSelectedProductSystem);
        this.$router.push({
            name: RouteNames.cmsIndex,
            params: {
                entityName: CmsEntityTypes.ProductDetails,
                entityId: 'start',
            },
        });
    }

    private goToTechnicalDocumentation() {
        this.$router.push({
            name: RouteNames.technicalDocumentation,
        });
    }

    private goToManualAPI() {
        if (this.$route.name !== RouteNames.manualAPI) {
            this.$router.push({
                name: RouteNames.manualAPI,
            });
        }
    }

    private goToProductVersions() {
        if (this.$route.name !== RouteNames.productVersions) {
            this.$router.push({
                name: RouteNames.productVersions,
            });
        }
    }

    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    private get userRoles() {
        return this.$store.getters['jwtData/userRoles'];
    }

    public get canSeeTranslationsItem() {
        return this.userRights.includes(UserRightsEnum.CMS_TRANSLATIONS);
    }

    public get canViewUsersTab() {
        return this.userRights.includes(UserRightsEnum.VIEW_USER);
    }

    public get canSeeCMSItem() {
        return this.userRights.includes(UserRightsEnum.CMS_CONTENT);
    }

    public get canSeeCatalogueItem() {
        return this.userRights.includes(UserRightsEnum.CMS_CATALOG);
    }

    public get canSeeManualAPITab() {
        return this.userRights.includes(UserRightsEnum.CMS_CATALOG);
    }

    public get canSeeProductVersions() {
        return this.userRights.includes(UserRightsEnum.VIEW_PRODUCT_VERSIONS);
    }

    private get isSuperAdmin() {
        return this.userRoles.includes('ROLE_SUPER_ADMIN');
    }

    private get currentUserGroup() {
        return UserGroupRepository.getById(String(this.$store.getters['jwtData/currentUserGroup']));
    }

    private onToggleLoading() {
        this.isLoading = !this.isLoading;
    }
}
