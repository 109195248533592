
import { Component, Prop, Vue } from 'vue-property-decorator';
import Translation from '@/models/Translation';
import Locale from '@/models/Locale';
import LocaleRepository from '@/repositories/LocaleRepository';
import moment from 'moment';
import i18n from '@/i18n';
import { LocalStorageService } from '@/services/LocalStorageService';
import User from '@/models/User';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';

@Component({
    name: 'LanguagePicker',
})
export default class LanguagePicker extends Vue {
    @Prop() private currentUser!: User;

    private pendingLocale: null | Locale = null;

    private get locales() {
        return LocaleRepository.getAll();
    }

    private get currentLocale() {
        return LocaleRepository.getLocaleByCode(this.$i18n.locale);
    }

    private get isSaveDisabled() {
        if (this.pendingLocale == null) {
            return true;
        }

        return this.pendingLocale.code === this.$i18n.locale;
    }

    private async onUpdateLocale() {
        if (this.currentUser == null || this.pendingLocale == null) {
            return;
        }

        this.$i18n.locale = this.pendingLocale.code;
        moment.locale(i18n.locale);

        try {
            await Translation.getAll();
            await User.updateCurrent({
                localeId: this.pendingLocale.id,
                id: this.currentUser.id,
            });
            await User.fetchAndSetToken();
        } catch (e) {
            return;
        }

        this.$emit('localeChange', this.pendingLocale.id);

        LocalStorageService.save('languageLocale', this.pendingLocale.code);
        LocalStorageService.remove('lastSelectedTab');

        this.emitDataChanges(false);

        this.$notification.success({
            message: this.$t('Jezik uspješno promijenjen') as string,
            description: ``,
        });
    }

    private async onLocaleChange(localeId: string) {
        const newLocale = LocaleRepository.getLocaleById(localeId);
        if (newLocale == null) {
            return;
        }

        this.pendingLocale = newLocale;
        this.emitDataChanges(true);
    }

    private emitDataChanges(state: boolean) {
        EventBus.$emit(EventBusEvents.changesInDataMade, {
            state,
        });
    }

    private async created() {
        EventBus.$on(EventBusEvents.saveAllUserSettings, this.onUpdateLocale);
        try {
            await Locale.getAll();
        } catch (e) {
            let error;

            if (e instanceof Error) {
                error = e.message;
            } else {
                error = (e as { response: { data: { meta: { message: string } } } }).response.data.meta.message;
            }

            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error,
            });
            return;
        }
    }

    private async beforeDestroy() {
        EventBus.$off(EventBusEvents.saveAllUserSettings, this.onUpdateLocale);
    }
}
